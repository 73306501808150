exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-content-contentful-ideetipp-title-js": () => import("./../../../src/pages/content/{ContentfulIdeetipp.title}.js" /* webpackChunkName: "component---src-pages-content-contentful-ideetipp-title-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-ideen-tipps-js": () => import("./../../../src/pages/ideen+tipps.js" /* webpackChunkName: "component---src-pages-ideen-tipps-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspiration-produkte-kurz-json-aw-product-id-js": () => import("./../../../src/pages/inspiration/{ProdukteKurzJson.aw_product_id}.js" /* webpackChunkName: "component---src-pages-inspiration-produkte-kurz-json-aw-product-id-js" */),
  "component---src-pages-kategorien-js": () => import("./../../../src/pages/kategorien.js" /* webpackChunkName: "component---src-pages-kategorien-js" */),
  "component---src-templates-inspirationen-template-js": () => import("./../../../src/templates/inspirationen-template.js" /* webpackChunkName: "component---src-templates-inspirationen-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

